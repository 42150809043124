<template>
<section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="!hasRecord && !isLoading">
        <h4 class="alert-heading">
            Error fetching invoice data
        </h4>
        <div class="alert-body">
            No invoice found with this invoice id. Check
            <b-link class="alert-link" :to="{ name: 'apps-invoice-list'}">
                Invoice List
            </b-link>
            for other invoices.
        </div>
    </b-alert>

    <b-row v-if="hasRecord" class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="9">
            <b-card no-body class="invoice-preview-card">
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">

                    <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                        <!-- Header: Left Content -->
                        <div>
                            <div class="logo-wrapper">
                                <h3 class="text-primary invoice-logo">
                                    <logo />
                                </h3>
                            </div>
                            <p class="card-text mb-25">
                                Riverside Drive (off Chiromo Road)
                            </p>
                            <p class="card-text mb-0">
                                P.O BOX 44540 – 00100
                            </p>
                            <p class="card-text mb-0">
                                NAIROBI, KENYA
                            </p>
                        </div>

                        <!-- Header: Right Content -->
                        <div class="mt-md-0 mt-2">
                            <h4 class="invoice-title">
                                Invoice
                                <span class="invoice-number">#{{ invoiceDT.invoiceId }}</span>
                            </h4>
                            <div class="invoice-date-wrapper">
                                <p class="invoice-date-title">
                                    Date Issued:
                                </p>
                                <p class="invoice-date">
                                    {{ invoiceDT.created | formatDate}}
                                </p>
                            </div>
                            <div class="invoice-date-wrapper">
                                <p class="invoice-date-title">
                                    Due Date:
                                </p>
                                <p class="invoice-date">
                                    {{ invoiceDT.due_date | formatDate}}
                                </p>
                            </div>
                        </div>
                    </div>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                <!-- Invoice Client & Payment Details -->
                <b-card-body v-if="hasRecord" class="invoice-padding pt-0">
                    <b-row class="invoice-spacing">

                        <!-- Col: Invoice To -->
                        <b-col cols="6" xl="6" class="p-0">
                            <h6 class="mb-2">
                                Invoice To:
                            </h6>
                            <h6 class="mb-25">
                                {{ invoiceDT.full_name }}
                            </h6>
                            <p class="card-text mb-25">
                                {{ invoiceDT.client_name }}
                            </p>
                            <p class="card-text mb-0">
                                {{ invoiceDT.client_email }}
                            </p>
                        </b-col>

                        <!-- Col: Payment Details -->
                        <b-col xl="6" cols="6" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                            <div>
                                <h6 class="mb-2">
                                    Payment Details:
                                </h6>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td class="pr-1">
                                                Total Due:
                                            </td>
                                            <td><span class="font-weight-bold">{{ invoiceDT.amount | formatAmount }}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">
                                                Acc:
                                            </td>
                                            <td>{{ invoiceDT.reference_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">
                                                Paybill:
                                            </td>
                                            <td>4002191</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>

                <!-- Invoice Description: Table -->
                <b-table-lite responsive :items="invoice" :fields="['taskDescription', 'amount']">
                    <template #cell(taskDescription)="data">
                        <b-card-text class="font-weight-bold mb-25">
                            {{ data.item.service_name }}
                        </b-card-text>
                        <b-card-text>
                            {{ data.item.description }}
                        </b-card-text>
                    </template>
                    <template #cell(amount)="data">
                        <b-link class="font-weight-bold">
                            {{data.item.currency}} {{ data.item.amount | formatAmount}}
                        </b-link>
                    </template>
                </b-table-lite>

                <!-- Invoice Description: Total -->
                <b-card-body class="invoice-padding pb-0">
                    <b-row>

                        <!-- Col: Sales Persion -->
                        <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                            <b-card-text class="mb-0">
                            </b-card-text>
                        </b-col>

                        <!-- Col: Total -->
                        <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                            <div class="invoice-total-wrapper">
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">
                                        Sub:
                                    </p>
                                    <p class="invoice-total-amount">
                                        {{invoiceDT.currency}} {{ invoiceDT.amount | formatAmount}}
                                    </p>
                                </div>
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">
                                        Tax:
                                    </p>
                                    <p class="invoice-total-amount">
                                        {{invoiceDT.vat_tax_amount}}
                                    </p>
                                </div>
                                <hr class="my-50">
                                <div class="invoice-total-item">
                                    <p class="invoice-total-title">
                                        Total:
                                    </p>
                                    <p class="invoice-total-amount">
                                        {{invoiceDT.currency}} {{ Number(invoiceDT.amount) + Number(invoiceDT.vat_tax_amount)  | formatAmount}}
                                    </p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                <!-- Note -->
                <b-card-body class="invoice-padding pt-0">
                    <span class="font-weight-bold">Note: </span>
                    <span>Thank You for your services!</span>
                </b-card-body>
            </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="3" xl="3" class="invoice-actions">
            <b-card>

                <!-- Button: Send Invoice -->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-invoice variant="primary" class="mb-75" block @click="downloadInvoice" id="download">
                    Print Invoice
                </b-button>

                <!-- Button: DOwnload -->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mb-75" block @click="downloadInvoice" id="download">
                    <b-spinner small v-show="isLoading" /> Download
                </b-button>

            </b-card>
        </b-col>
    </b-row>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true" :paginate-elements-by-height="1400" filename="invoice" :pdf-quality="2" :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait" ref="html2Pdf" @progress="onProgress($event)" @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)">
        <section slot="pdf-content">
            <!-- PDF Content Here -->
            <b-row v-if="hasRecord" class="invoice-preview">
                <!-- Col: Left (Invoice Container) -->
                <b-col cols="12">
                    <b-card no-body class="invoice-preview-card">
                        <!-- Header -->
                        <b-card-body class="invoice-padding pb-0">

                            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                                <!-- Header: Left Content -->
                                <div>
                                    <div class="logo-wrapper">

                                        <h3 class="text-primary invoice-logo">
                                            <logo />
                                        </h3>
                                    </div>
                                    
                                    <p class="card-text mb-25">
                                        Riverside Drive (off Chiromo Road)
                                    </p>
                                    <p class="card-text mb-0">
                                        P.O BOX 44540 – 00100
                                    </p>
                                    <p class="card-text mb-0">
                                        NAIROBI, KENYA
                                    </p>
                                </div>

                                <!-- Header: Right Content -->
                                <div class="mt-md-0 mt-2">
                                    <h4 class="invoice-title">
                                        Invoice
                                        <span class="invoice-number">#{{ invoiceDT.invoiceId }}</span>
                                    </h4>
                                    <div class="invoice-date-wrapper">
                                        <p class="invoice-date-title">
                                            Date Issued:
                                        </p>
                                        <p class="invoice-date">
                                            {{ invoiceDT.created | formatDate}}
                                        </p>
                                    </div>
                                    <div class="invoice-date-wrapper">
                                        <p class="invoice-date-title">
                                            Due Date:
                                        </p>
                                        <p class="invoice-date">
                                            {{ invoiceDT.due_date | formatDate}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>

                        <!-- Spacer -->
                        <hr class="invoice-spacing">

                        <!-- Invoice Client & Payment Details -->
                        <b-card-body v-if="hasRecord" class="invoice-padding pt-0">
                            <b-row class="invoice-spacing">

                                <!-- Col: Invoice To -->
                                <b-col cols="12" xl="6" class="p-0">
                                    <h6 class="mb-2">
                                        Invoice To:
                                    </h6>
                                    <h6 class="mb-25">
                                        {{ invoiceDT.full_name }}
                                    </h6>
                                    <p class="card-text mb-25">
                                        {{ invoiceDT.client_name }}
                                    </p>
                                    <p class="card-text mb-0">
                                        {{ invoiceDT.client_email }}
                                    </p>
                                </b-col>

                                <!-- Col: Payment Details -->
                                <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                                    <div>
                                        <h6 class="mb-2">
                                            Payment Details:
                                        </h6>
                                        <table>
                                            <tbody>
                                                <tr>
                                            <td class="pr-1">
                                                Total Due:
                                            </td>
                                            <td><span class="font-weight-bold">{{ invoiceDT.amount | formatAmount}}</span></td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">
                                                Acc:
                                            </td>
                                            <td>{{ invoiceDT.reference_number }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pr-1">
                                                Paybill:
                                            </td>
                                            <td>4002191</td>
                                        </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>

                        <!-- Invoice Description: Table -->
                        <b-table-lite responsive :items="invoice" :fields="['taskDescription', 'amount']">
                            <template #cell(taskDescription)="data">
                                <b-card-text class="font-weight-bold">
                                    {{ data.item.service_name }}
                                </b-card-text>
                                <b-card-text>
                                    {{ data.item.description }}
                                </b-card-text>
                            </template>
                            <template #cell(amount)="data">
                                <b-link class="font-weight-bold">
                                    {{data.item.currency}} {{ data.item.amount | formatAmount}}
                                </b-link>
                            </template>
                        </b-table-lite>

                        <!-- Invoice Description: Total -->
                        <b-card-body class="invoice-padding pb-0">
                            <b-row>

                                <!-- Col: Sales Persion -->
                                <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                                    <b-card-text class="mb-0">
                                    </b-card-text>
                                </b-col>

                                <!-- Col: Total -->
                                <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                                    <div class="invoice-total-wrapper">
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title">
                                                Sub:
                                            </p>
                                            <p class="invoice-total-amount">
                                                {{invoiceDT.currency}} {{ invoiceDT.amount | formatAmount}}
                                            </p>
                                        </div>
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title">
                                                Tax:
                                            </p>
                                            <p class="invoice-total-amount">
                                                {{invoiceDT.vat_tax_amount}}
                                            </p>
                                        </div>
                                        <hr class="my-50">
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title">
                                                Total:
                                            </p>
                                            <p class="invoice-total-amount">
                                                {{invoiceDT.currency}} {{ (invoiceDT.amount + invoiceDT.vat_tax_amount) | formatAmount}}
                                            </p>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>

                        <!-- Spacer -->
                        <hr class="invoice-spacing">

                        <!-- Note -->
                        <b-card-body class="invoice-padding pt-0">
                            <span class="font-weight-bold">Note: </span>
                            <span>Thank You for your services!</span>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </section>
    </vue-html2pdf>
    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment />
</section>
</template>

<script>
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import store from '@/store'
import $ from 'jquery'
import router from '@/router'
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BSpinner,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import numeral from "numeral"
import moment from "moment"
import VueHtml2pdf from 'vue-html2pdf'
export default {
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        VueHtml2pdf,
        Logo,
        InvoiceSidebarAddPayment,
        InvoiceSidebarSendInvoice,
        BSpinner
    },
    setup() {
        const invoiceData = ref(null)
        const paymentDetails = ref({})

        // Invoice Description
        // ? Your real data will contain this information
        const invoiceDescription = [{
                taskTitle: 'Native App Development',
                taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
                rate: '$60.00',
                hours: '30',
                total: '$1,800.00',
            },
            {
                taskTitle: 'UI Kit Design',
                taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
                rate: '$60.00',
                hours: '20',
                total: '$1200.00',
            },
        ]

        const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

        // Register module
        if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
        })

        store.dispatch('app-invoice/fetchInvoice', {
                id: router.currentRoute.params.id
            })
            .then(response => {
                invoiceData.value = response.data.invoice
                paymentDetails.value = response.data.paymentDetails
            })
            .catch(error => {
                if (error.response.status === 404) {
                    invoiceData.value = undefined
                }
            })

        const printInvoice = () => {
            window.print()
        }

        return {
            invoiceData,
            paymentDetails,
            invoiceDescription,
            printInvoice,
        }
    },
    data() {
        return {
            isLoading: true,
            invoice: [],
            invoiceDT: {},
            hasRecord: false,
        }
    },
    mounted() {
        this.getInvoice()
    },
    methods: {
        downloadInvoice() {
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(val) {
            if (val == 70) {
                this.isLoading = true
                $('#download').html(' Please Wait ...');
            } else {
                $('#download').html('Download');
                this.isLoading = false
            }
            console.log(val)
        },
        hasStartedGeneration(val) {

            console.log("Start " + val)

        },
        hasGenerated(val) {
            console.log("End " + val)

        },
        getInvoice() {
            let vm = this
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'account/v1/view/invoices',
                type: "get",
                async: true,
                data: {
                    invoiceId: router.currentRoute.params.id,
                    limit: 1,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    } else {

                        if (response.data.data == []) {
                            vm.hasRecord = false
                        } else {
                            vm.hasRecord = true
                            vm.invoiceDT = response.data.data.data[0]
                            vm.invoice = response.data.data.data
                            console.log("Has record" + JSON.stringify(vm.invoiceDT))
                        }

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        }

    },
    filters: {
        formatAmount: function (value) {
            return numeral(value).format("0,0.00");
        },
        formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

    // Global Styles
    body {
        background-color: transparent !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        .row.invoice-preview {
            .col-md-8 {
                max-width: 100%;
                flex-grow: 1;
            }

            .invoice-preview-card {
                .card-body:nth-of-type(2) {
                    .row {
                        >.col-12 {
                            max-width: 50% !important;
                        }

                        .col-12:nth-child(2) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>
